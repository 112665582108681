window.googletag = window.googletag || { cmd: [] };

window.htAdsJSON = {
  adUnitSlots: {
    adUnitSlotsDesktopPostRegular: {},
  },
  prebidAdUnits: {
    prebidAdUnitsDesktopPostRegular: {},
  },
  pageKeyValues: window.adsJSON.pageLevelKVTargeting,
};

googletag.cmd.push(() => {
  // Size Mapping
  const sizeMappingSetting = window.adsJSON.sizeMappings;
  Object.keys(sizeMappingSetting).forEach((sizeName) => {
    const sizeData = sizeMappingSetting[sizeName];

    const googletagsize = googletag.sizeMapping();
    Object.keys(sizeData).forEach((breakpoint) => {
      const size = sizeData[breakpoint];
      // eslint-disable-next-line no-eval
      googletagsize.addSize(JSON.parse(breakpoint), eval(size));
    }); // end foreachsize dat

    window[sizeName] = googletagsize.build();
  }); // end foreach size map
});

// Grab the appropriate prebid map (determined by BE based off of the vertical)
window.htPrebidMap = window.adsJSON.prebidMap;

// foreach window.adsJSON
window.adsJSON.adUnits.forEach((slot) => {
  // build basic slot info
  window.htAdsJSON.adUnitSlots.adUnitSlotsDesktopPostRegular[slot.divId] = {
    unitPath: slot.adCode,
    sizes: slot.sizes,
    htmlId: slot.divId,
    platform: slot.platform,
    pageType: "ros",
    sizeMapping: slot.sizeMapping,
    keyValues: slot.kvTargeting,
  };

  const noPrebidorTAM = [
    "RightRail_Vendor",
    "Homepage_Billboard",
    "Billboard",
    "WhatsNew_Billboard",
  ];

  if (noPrebidorTAM.includes(slot.name)) {
    window.htAdsJSON.adUnitSlots.adUnitSlotsDesktopPostRegular[
      slot.divId
    ].options = {
      requestBid: slot.requestBid ?? false,
      amazonBid: slot.amazonBid ?? false,
      no_refresh: slot.noRefresh ?? false,
      collapseEmpty: slot.collapseEmpty ?? false,
    };
  } else {
    window.htAdsJSON.adUnitSlots.adUnitSlotsDesktopPostRegular[
      slot.divId
    ].options = {
      adBlockRender: slot.adBlockRender ?? false,
    };
  }
  if (
    slot.controlledBy === "browsi" &&
    window.browsi &&
    (window.browsiManaged === undefined || window.browsiManaged === 1)
  ) {
    window.htAdsJSON.adUnitSlots.adUnitSlotsDesktopPostRegular[
      slot.divId
    ].options.controlledBy = slot.controlledBy;
    window.htAdsJSON.adUnitSlots.adUnitSlotsDesktopPostRegular[
      slot.divId
    ].keyValues.AmazonTAM = "HT";
    window.htAdsJSON.adUnitSlots.adUnitSlotsDesktopPostRegular[
      slot.divId
    ].keyValues.PrebidTech = "BR";
  }

  if (!window.htPrebidMap[slot.name]) {
    return;
  }

  window.htAdsJSON.prebidAdUnits.prebidAdUnitsDesktopPostRegular[slot.adCode] =
    window.htPrebidMap[slot.name];
  const adagio = window.htAdsJSON.prebidAdUnits.prebidAdUnitsDesktopPostRegular[
    slot.adCode
  ].bids.find((el) => el.bidder === "adagio");
  if (adagio) {
    adagio.params.site = window.adConfig.adagioSite
      ? window.adConfig.adagioSite
      : window.location.hostname.replace("www.", "").replace(/\./g, "-");
  }
}); // end foreach window.adsJSON.adUnits slot
